






















































import {Component, Ref, Vue} from "vue-property-decorator"
import Marked from 'marked'
import Family from "@/models/Family";
import FamilyService from "@/services/FamilyService";

@Component
export default class FamilyView extends Vue {
    loading: boolean = false
    family: Family = new Family()
    @Ref() readonly markedId!: HTMLParagraphElement


    created() {
        FamilyService.getFamily(this, parseInt(this.$route.params.id))
            .then(() => {
                this.markedId.innerHTML = Marked(this.family.body!)
            })

    }

    mounted() {
        this.markedId.innerHTML = Marked(this.family.body!) + "prueba"
    }

    // date(createdAt: string) {
    //     return format(new Date(createdAt), "dd $ MMMM, YYY", {locale: es})
    // }

    goingTo(dir : string) {
        window.open(dir, "_blank")
    }

}
